import { useParams, useNavigate, Link } from "react-router-dom";
import API from "../API";
import { useEffect, useRef, useState } from "react";
import { useMint } from "../hooks/useMint";
import { createUmi } from '@metaplex-foundation/umi-bundle-defaults'
import { RPC, CANDY_MACHINE_ID_STR, treasuryStr } from "../anchor/setup"
import {
    publicKey,
    generateSigner,
    transactionBuilder,
    keypairIdentity,
    some,
    TransactionBuilderSendAndConfirmOptions,
    TransactionBuilder
} from '@metaplex-foundation/umi';
import { useConnection, useWallet } from "@solana/wallet-adapter-react";


const CANDY_MACHINE_ID = publicKey(CANDY_MACHINE_ID_STR);
const treasury = publicKey(treasuryStr);

export default function Nft() {  
  const [mint, loadingMap] = useMint();

  const wallet = useWallet();

  const [mintAmountA, setMintAmountA] = useState(0)
  const [mintAmountB, setMintAmountB] = useState(0)

  return (
    <>
      <div className="mx-auto max-w-[1000px]">
        {/* <div className="mb-6 flex items-center">
          <h3 className="ct-font-silk ct-text-gt1 text-[2.5rem]">
            nft and buff
          </h3>
        </div> */}
        <ul className="grid grid-cols-2 gap-10">
          {new Array(2).fill(0).map((_, item, index) => (
            <li className="bg-black bg-opacity-50" key={item}>
              <img src="/nft_sample.jpg" alt="" className="w-full" />
              <p className="ct-font-silk flex items-center justify-between p-2 text-xl">
                <span className={`${item === 0 && "ct-text-gt5"}`}>
                  {item === 0 && "CALL Genesis Epic"}
                  {item === 1 && "CALL Genesis Rare"}
                </span>
                <span className="h-16 w-16 overflow-hidden">
                  <img
                    src={`./i_${(item === 0 && "gold") || (item === 1 && "silver") || (item > 1 && "bronze")}.svg`}
                    alt=""
                    className="-ml-[10px] -mt-[2px] w-[84px] max-w-none"
                  />
                </span>
              </p>

              <div className="mb-3 flex items-center justify-between px-2">
                <div className="flex gap-1">
                  <button className="btn h-10 min-h-min" onClick={() => {
                    if (item === 0) {
                      if (mintAmountA - 1 >= 0) {
                        setMintAmountA(mintAmountA - 1)
                      }
                    } else if (item === 1) {
                      if (mintAmountB - 1 >= 0) {
                        setMintAmountB(mintAmountB - 1)
                      }
                    }
                  }}>-</button>
                  <input
                    type="number"
                    pattern="\d*"
                    className="ct-num-input input h-10 w-16 pl-0 text-center text-white focus:outline-none"
                    value={item === 0 ? mintAmountA : mintAmountB}
                    onChange={(e) => {
                      const amount = parseInt(e.target.value)
                      item === 0 ? setMintAmountA(amount) : setMintAmountA(amount)
                    }}
                  />
                  <button className="btn h-10 min-h-min" onClick={() => {
                    if (item === 0) {
                      setMintAmountA(mintAmountA + 1)
                    } else if (item === 1) {
                      setMintAmountB(mintAmountB + 1)
                    }
                  }}>+</button>
                </div>
                <div className="text-right text-sm">
                  <div>
                    Total Minted{" "}
                    <span className="text-base text-white">123/999</span>
                  </div>
                  <div>
                    Ends in{" "}
                    <span className="text-base text-white">03 08 40 42</span>
                  </div>
                </div>
              </div>

              <div className="mb-3 flex justify-between px-2">
                <span>Mint Fee</span>
                <span>
                  {item === 0 && "1 SOL"}
                  {item === 1 && "0.5 SOL"}
                </span>
              </div>

              <button onClick={() => {
                mint({amount: item === 0 ? mintAmountA : mintAmountB,mintType: item.toString()})
              }} className="btn h-10 min-h-min w-full rounded-none bg-gradient-to-r from-[#00FF80] to-[#00FFE2] text-base text-black">
                {loadingMap[item.toString()] ? (
                  <span className="loading loading-infinity loading-lg text-[#4c4c4c]"></span>
                ) : (
                  "Mint"
                )}
              </button>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}
