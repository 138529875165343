import { useState } from "react";
import {
  useConnection,
  useWallet,
  useAnchorWallet,
} from "@solana/wallet-adapter-react";
import { BN } from "bn.js";

import { programId, tokenIssuer, RPC, CANDY_MACHINE_ID_STR, treasuryStr } from "../anchor/setup";
import {
  PublicKey,
  SystemProgram,
  SYSVAR_RENT_PUBKEY,
  LAMPORTS_PER_SOL,
} from "@solana/web3.js";
import { createUmi } from '@metaplex-foundation/umi-bundle-defaults'
import {
  publicKey,
  generateSigner,
  transactionBuilder,
  keypairIdentity,
  some,
  TransactionBuilderSendAndConfirmOptions,
  TransactionBuilder
} from '@metaplex-foundation/umi';
import { setComputeUnitLimit } from '@metaplex-foundation/mpl-toolbox';
import { walletAdapterIdentity } from '@metaplex-foundation/umi-signer-wallet-adapters';
import { fetchCandyMachine, mintV2, mplCandyMachine, safeFetchCandyGuard, route } from "@metaplex-foundation/mpl-candy-machine";

import { AnchorProvider, Wallet, Idl, Program } from "@coral-xyz/anchor";
import IDL from "../anchor/pumpfun.json";

import { toast } from "react-toastify";

global.Buffer = require("buffer").Buffer;
const CANDY_MACHINE_ID = publicKey(CANDY_MACHINE_ID_STR);
const treasury = publicKey(treasuryStr);

export const useMint = () => {
  const { publicKey, sendTransaction } = useWallet();
  const wallet = useAnchorWallet();
  const { connection } = useConnection();
  const [loadingMap, setLoadingMap] = useState<any>({});

  async function getProvider(rpc: string, wallet: any) {
    const provider = new AnchorProvider(connection, wallet, {
      skipPreflight: true,
    });
    return provider;
  }

  const mint = async ({
    amount, mintType
  }: {
    amount: number;
    mintType: string;
  }) => {
    if (!publicKey || !wallet || !amount) return;
    const loadingState: any = {...loadingMap}
    loadingState[mintType] = true
    setLoadingMap(loadingState);

    const umi = createUmi(RPC)
      .use(walletAdapterIdentity(wallet))
      .use(mplCandyMachine());

    try {

      const candyMachine = await fetchCandyMachine(
        umi,
        CANDY_MACHINE_ID,
      );
      // Fetch the Candy Guard.
      const candyGuard = await safeFetchCandyGuard(
          umi,
          candyMachine.mintAuthority,
      );
      const nftMint = generateSigner(umi);
      const transaction = await transactionBuilder()
        .add(setComputeUnitLimit(umi, { units: 800_000 }))
        // .add(
        //     mintV2(umi, {
        //         candyMachine: candyMachine.publicKey,
        //         candyGuard: candyGuard?.publicKey,
        //         nftMint,
        //         collectionMint: candyMachine.collectionMint,
        //         collectionUpdateAuthority: candyMachine.authority,
            
        //         mintArgs: {
        //             solPayment: some({
        //                 destination: treasury,
        //             }),
        //             mintLimit: some({
        //                 id: 1
        //             })
        //         },
        //     })
        // )
        for (let i = 0; i < amount; i++) {
          transaction.add(
            mintV2(umi, {
              candyMachine: candyMachine.publicKey,
              candyGuard: candyGuard?.publicKey,
              nftMint,
              collectionMint: candyMachine.collectionMint,
              collectionUpdateAuthority: candyMachine.authority,
          
              mintArgs: {
                  solPayment: some({
                      destination: treasury,
                  }),
                  mintLimit: some({
                      id: 1
                  })
              },
          })
          )
        }
      // console.log(`transaction: ${JSON.stringify(transaction)}`)
    
      try {
        let { signature } = await transaction.sendAndConfirm(umi, {
            confirm: { commitment: "confirmed" },
        });
        console.log(`signature: ${signature}`)
        toast.success("Mint NFT Successed!");
        // console.log(`https://solscan.io/tx/${tx}?cluster=devnet`);
        return signature;
      } catch (error) {
        const loadingState: any = {...loadingMap}
        loadingState[mintType] = false
        setLoadingMap(loadingState);
        toast.error("transaction fail!");
      }
    
    } catch (error) {
        console.log('Error minting NFTs: ', error);
        throw error;
    } finally {
      const loadingState: any = {...loadingMap}
      loadingState[mintType] = false
      setLoadingMap(loadingState);
    }
  };

  return [mint, loadingMap] as const;
};
