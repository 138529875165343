import React, { useMemo } from "react";
import ScrollToTop from "./components/ScrollToTop";

import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
  WalletModalProvider,
  WalletMultiButton,
} from "@solana/wallet-adapter-react-ui";
import { clusterApiUrl } from "@solana/web3.js";

import "./App.css";
import "@solana/wallet-adapter-react-ui/styles.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./routes/home";
import Tokens from "./routes/tokens";
import TokensDetails from "./routes/tokensdetails";
import Hours48rush from "./routes/hours48rush";
import Hours48rushAviablea from "./routes/hours48rushaviablea";
import Ctofund from "./routes/ctofund";
import Ctofundcreate from "./routes/ctofundcreate";
import Ctofundvote from "./routes/ctofundvote";
import Myassets from "./routes/myassets";
import Airdrop from "./routes/applyairdrop";
import Nft from "./routes/nft";
import DefaultLayout from "./layout/default";
import {
  PhantomWalletAdapter,
  SolflareWalletAdapter,
  TorusWalletAdapter,
  LedgerWalletAdapter,
  MathWalletAdapter,
  TokenPocketWalletAdapter,
  CoinbaseWalletAdapter,
  SolongWalletAdapter,
  Coin98WalletAdapter,
  SafePalWalletAdapter,
  BitpieWalletAdapter,
  BitgetWalletAdapter,
  CloverWalletAdapter,
  CoinhubWalletAdapter,
  WalletConnectWalletAdapter,
  // TrustWalletAdapter
} from "@solana/wallet-adapter-wallets";

import Create from "./routes/create";
import { RPC as endpoint } from "./anchor/setup";
function App() {
  // The network can be set to 'devnet', 'testnet', or 'mainnet-beta'.
  const network = WalletAdapterNetwork.Devnet;

  // You can also provide a custom RPC endpoint.
  // const endpoint = useMemo(() => clusterApiUrl(network), [network]);

  const wallets = useMemo(
    () => [
      // new PhantomWalletAdapter(),
      // new Coin98WalletAdapter(),
      // new WalletConnectWalletAdapter(),
      // if desired, manually define specific/custom wallets here (normally not required)
      // otherwise, the wallet-adapter will auto detect the wallets a user's browser has available
    ],
    [network],
  );

  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets} autoConnect>
        <WalletModalProvider>
          <ToastContainer />
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<DefaultLayout />}>
              <Route index element={<Home />} />
              <Route path="/tokens" element={<Tokens />} />
              <Route
                path="/tokensdetails/:address"
                element={<TokensDetails />}
              />
              <Route path="/h48r" element={<Hours48rush />} />
              <Route path="/h48ra/:rushId" element={<Hours48rushAviablea />} />
              <Route path="/cto" element={<Ctofund />} />
              <Route path="/ctocreate" element={<Ctofundcreate />} />
              <Route path="/ctovote/:address" element={<Ctofundvote />} />
              <Route path="/my" element={<Myassets />} />
              <Route path="/airdrop" element={<Airdrop />} />
              <Route path="/nft" element={<Nft />} />
              <Route path="/create" element={<Create />} />

              <Route
                path="*"
                element={
                  <main className="p-4">
                    <p>There's nothing here!</p>
                  </main>
                }
              />
            </Route>
          </Routes>
        </WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
}

export default App;
