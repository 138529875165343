import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import API from "../API";
import { num2str } from "../lib/utils";

export default function Ctofund() {
  let [CTOFundingList, setCTOFundingList] = useState({
    fundingld: 0, // this round id
    totalVotes: 0,
    isOverHalf: 0,
    canProposal: 0,
    startTime: 0,
    endTime: 0,
    pricePool: "",
    fundingTokenInfoRespList: [
      { address: "", name: "", ticker: "", image: "", votes: 0 },
    ],
  });
  const pageCTOFundingList = async function () {
    let res = await API.meme.pageCTOFundingList({
      page: {
        offset: 0,
        limit: 20,
      },
    });
    if (res.data) {
      setCTOFundingList(res.data);
    }
  };

  useEffect(() => {
    pageCTOFundingList();
  }, []);

  return (
    <div className="ct-cto-bg">
      {/* CTO FUND -Section */}
      <div className="mx-auto mb-20 flex max-w-[1400px] flex-col items-center gap-4">
        <h1 className="ct-font-silk ct-text-gt4 text-[40px] text-[#FF8700]">
          CTO FUND
        </h1>
        <p className="text-center text-2xl text-[#C1C1C1]">
          You can create a voting proposal from shitcoins pool. It costs 10,000
          $call which you can earn by trading in CALL.MEME. If your proposal
          win, your cost of creation this proposal will be completely refund.
          More than that u will earn 10% of total budget
        </p>
      </div>

      {/* Chart -Section */}
      <div className="mx-auto mb-14 grid max-w-[1200px] grid-cols-[auto_1fr_auto]">
        <div className="w-[325px] pt-[90px] text-center">
          <div className="relative mb-9 text-lg">
            <img
              src={`/taper_lv${CTOFundingList.isOverHalf ? 2 : 1}.png`}
              alt=""
              className="mx-auto w-[238px]"
            />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="120"
              height="215"
              viewBox="0 0 120 215"
              fill="none"
              className="absolute left-[-10px] top-[95px]"
            >
              <g clipPath="url(#clip0_547_2732)">
                <path
                  d="M118.894 -0.823107C88.2272 -7.65644 20.8939 14.6769 6.89446 81.6769C-10.3696 164.301 37.7276 203.344 50.3943 209.677"
                  stroke="url(#paint0_linear_547_2732)"
                  strokeWidth="4"
                />
                <path
                  d="M42.8947 186.177C41.728 188.343 40.0947 194.377 42.8947 201.177C45.6947 207.977 49.7279 209.677 51.3945 209.677C45.2279 208.677 32.1945 207.777 29.3945 212.177"
                  stroke="#FF8700"
                  strokeWidth="4"
                />
              </g>
              <defs>
                <linearGradient
                  id="paint0_linear_547_2732"
                  x1="61.0456"
                  y1="-2"
                  x2="61.0456"
                  y2="209.677"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#FF8700" stopOpacity="0" />
                  <stop offset="1" stopColor="#FF8700" />
                </linearGradient>
                <clipPath id="clip0_547_2732">
                  <rect
                    width="120"
                    height="214"
                    fill="white"
                    transform="translate(0 0.823242)"
                  />
                </clipPath>
              </defs>
            </svg>
            <span className="absolute left-0 top-[130px] w-full text-[#17181B]">
              LV.1
            </span>
            <span className="absolute left-0 top-[245px] w-full text-white">
              LV.2
            </span>
          </div>
          <div className="text-3xl font-bold tracking-widest text-[#FF8700]">
            LV1 {num2str(Math.floor(Number(CTOFundingList.pricePool) / 2))}
            SOL
          </div>
          <div className="flex items-center justify-center gap-2 text-lg text-[#FF8700]">
            {num2str(CTOFundingList.totalVotes)} more votes to
            <span className="text-[#B8B8B8]">
              LV.2 &#40;{num2str(Number(CTOFundingList.pricePool))}SOL&#41;
            </span>
            <span
              className="tooltip tooltip-bottom cursor-pointer"
              data-tip="The Score is about 1 season will cost someone sometimes words so long so long prize or some times when you  want"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <path
                  d="M8.75 0.25C3.92188 0.25 0 4.17188 0 9C0 13.8281 3.92188 17.75 8.75 17.75C13.5781 17.75 17.5 13.8281 17.5 9C17.5 4.17188 13.5781 0.25 8.75 0.25ZM9.375 14H8.125V12.75H9.375V14ZM9.59375 10.0312L9.48438 10.125C9.42187 10.1719 9.375 10.2812 9.375 10.375V11.5156H8.125V10.375C8.125 9.89063 8.34375 9.4375 8.70312 9.14062L8.8125 9.04688C9.90625 8.17188 10.4375 7.71875 10.4375 6.9375C10.4375 6 9.6875 5.25 8.75 5.25C7.78125 5.25 7.0625 5.96875 7.0625 6.9375H5.8125C5.8125 5.29688 7.10938 4 8.75 4C10.375 4 11.6875 5.3125 11.6875 6.9375C11.6875 8.35938 10.7188 9.14062 9.59375 10.0312Z"
                  fill="#B8B8B8"
                />
              </svg>
            </span>
          </div>
        </div>
        <div className="grid justify-items-end pt-[120px]">
          <div className="ct-cto-cylinder relative mr-[95px] h-[380px] w-[206px]">
            <div className="absolute -top-[70px] left-1/2 -translate-x-1/2">
              <img
                src="./coin_big_crown.png"
                alt=""
                className="absolute -left-[46px] -top-[47px] w-[88px] max-w-none"
              />
              <img
                src={CTOFundingList.fundingTokenInfoRespList[0]?.image}
                alt=""
                className="w-20"
              />
            </div>
            <p className="absolute left-1/2 top-6 -translate-x-1/2 text-2xl font-bold text-[#010101]">
              {CTOFundingList.fundingTokenInfoRespList[0]?.name}
            </p>
            <p className="ct-font-silk ct-text-gt4 absolute left-1/2 top-[90px] -translate-x-1/2 text-[56px] text-white">
              1
            </p>
          </div>
        </div>
        <div className="w-[320px] pt-[200px]">
          <div className="bg-[#17181B]">
            <table className="w-full text-center text-sm">
              <thead>
                <tr className="text-[#8E8E92]">
                  <th className="font-normal"># Rank</th>
                  <th className="font-normal">Coin</th>
                  <th className="font-normal">votes</th>
                  <th className="font-normal">&nbsp;</th>
                </tr>
              </thead>
              <tbody className="text-[#F4F5F7]">
                {CTOFundingList.fundingTokenInfoRespList
                  .slice(0, 6)
                  .map((_, item) => (
                    <tr
                      className={`h-10 ${item === 0 && "bg-[rgb(255_135_0)] bg-opacity-30"}`}
                      key={item}
                    >
                      <td>{item + 1}</td>
                      <td>
                        <div className="flex justify-center gap-1">
                          <span className="relative">
                            {item === 0 && (
                              <img
                                src="./coin_crown.png"
                                alt=""
                                className="absolute left-[-9px] top-[-10px]"
                              />
                            )}
                            <img src={_?.image} alt="" className="h-5 w-5" />
                          </span>
                          {_.ticker}
                        </div>
                      </td>
                      <td>{_.votes}</td>
                      <td>
                        <Link
                          to={`/ctovote/${_.address}`}
                          className="text-[#FF8700]"
                        >
                          VOTE
                        </Link>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* which token will win -Section */}
      <div className="mb-[90px] grid justify-center gap-4">
        <h2 className="ct-font-silk ct-text-gt4 text-[32px] text-[#FF8700]">
          which token will win?
        </h2>
        <div className="pb-10 text-center">
          <Link
            to="/ctocreate"
            className="btn h-[60px] rounded-xl bg-[#FF8700] px-6 text-2xl text-black hover:bg-[#FF8700]"
          >
            Propose Another Token
          </Link>
        </div>
      </div>

      {/* Big Table -Section */}
      <div className="mx-auto max-w-[1124px]">
        <table className="w-full border-separate border-spacing-y-1.5 text-center text-sm">
          <thead>
            <tr className="h-12 text-[#8E8E92]">
              <th className="font-normal"># Rank</th>
              <th className="font-normal">Name</th>
              {/* <th className="font-normal">Score</th> */}
              <th className="font-normal">votes</th>
              <th className="font-normal">&nbsp;</th>
            </tr>
          </thead>
          <tbody className="text-[#F4F5F7]">
            {CTOFundingList.fundingTokenInfoRespList.slice(6).map((_, item) => (
              <tr className="h-10 bg-[#151717]" key={item}>
                <td>{item + 7}</td>
                <td>
                  <div className="flex items-center justify-center gap-2">
                    <img
                      src={_?.image}
                      className="h-5 w-5 rounded-3xl border border-[#303030]"
                      alt=""
                    />
                    <Link to={""} className="text-[#BFDBFE]">
                      src={_.address.slice(0, 6)}
                    </Link>
                  </div>
                </td>
                {/* <td>{_.score}</td> */}
                <td>{num2str(_.votes)}</td>
                <td>
                  <Link to="/ctovote" className="text-[#FF8700]">
                    VOTE
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
